




















import { Component, Vue } from 'vue-property-decorator'
import VsFormGroup from '@/components/VsFormGroup/Index.vue'
import VsFormSection from '@/components/VsFormSection/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsRouterLink from '@/components/VsRouterLink/Index.vue'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
@Component({
    name: 'DevelopersSettings',
    components: {
        VsFormGroup,
        VsFormSection,
        VsContainer,
        VsLoader,
        VsRouterLink,
    },
})
export default class extends Vue {
    private loading = false
    private pageLoading = false

    $refs: any

    get user () {
        return UserModule.user
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get consoleId () {
        return get(AppModule.consoleConf, 'consoleId', '')
    }

    get consoleUserId () {
        return (UserModule.consoleUserId + 1000).toString(36)
    }

    get code () {
        const script = 'script'
        return `
<!-- Email Automation Tracker - Start -->
<${script} type="text/javascript" charset="utf-8">var $atmTRK={'settings':{'cid':'${this.consoleId}','uid':'${this.consoleUserId}'}};(function(){var st=document.createElement('script');st.type='text/javascript';st.async=true;st.defer=true;st.src=('https:'==document.location.protocol?'https://trk.mktaut.com':'http://trk.mktaut.com')+'/assets/goaltracking.js';var s=document.getElementsByTagName('script')[0];s.parentNode.insertBefore(st,s);})();</${script}>
<!-- Email Automation Tracker - End -->`
    }

    private copyToClipboard () {
        try {
            copyToClipboard(this.code)
            this.$root.$vsToast({
                heading: 'Codice copiato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Codice non copiato',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }
}
